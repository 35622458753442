<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF SHARE CAPITAL WITHDREW NOT YET
          APPROVE</h4>

        </v-toolbar-title>
      </v-toolbar>
      Print:
      <v-icon
        class="mr-2"
        color="warning"
        @click="print_data()"
      >
        {{icons.mdiPrinter}}
      </v-icon>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class=" text-uppercase">
              ID
            </th>
            <th class=" text-uppercase">
              DATE
            </th>
            <th class=" text-uppercase">
              NAME OF MEMBER
            </th>
            <th class=" text-uppercase">
              AMOUNT
            </th>
            <th class=" text-uppercase">
              CREATED BY
            </th>
            <th class=" text-uppercase">
              APPROVE BY GM
            </th>
            <th class="text-center text-uppercase">
              APPROVED BY BOARD
            </th>
            <th class="text-center text-uppercase">
              STATUS
            </th>
            <th class="text-center text-uppercase">
              Approve
            </th>
            <th class="text-center text-uppercase"
                v-if="position==='ADMIN'||position==='GENERAL MANAGER'">
              Dis-approved
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in loans_items"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ item.members.last_name+', '+item.members.first_name }}
            </td>
            <td>
              {{ item.members.share_capital.length>0?formatPrice(item.members.share_capital[0].balance):'' }}
            </td>
            <td>
              {{ item.created_by.name }}
            </td>
            <td>
              {{ item.approved_gm_by!=null?item.approved_gm_by.name:'' }}
            </td>
            <td>
              {{ item.approved_board_by!=null?item.approved_board_by.name:'' }}
            </td>
            <td class="text-center">
              <div v-if="item.is_approved_gm===0">
                <v-chip color="error" dark>PENDING TO GENERAL MANAGER</v-chip>
              </div>
              <div v-else-if="item.is_approved_board===0">
                <v-chip color="warning" dark>PENDING TO BOARD</v-chip>
              </div>
              <div v-else-if="item.is_done_teller===1">
                <v-chip color="success" dark>
                  RELEASED
                </v-chip>
              </div>
              <div v-else-if="item.is_approved_board===1">
                <v-chip color="success" dark>
                  APPROVED
                </v-chip>
              </div>
            </td>
            <td class="text-center">
              <div v-if="item.is_approved_gm===0 && position==='GENERAL MANAGER'">
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="actions(item)"
                >
                  {{icons.mdiCheckBold}}
                </v-icon>
              </div>
              <div
                v-if="item.is_approved_gm===1 &&item.is_approved_board===0  && position==='ADMIN'">
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="actions(item)"
                >
                  {{icons.mdiCheckBold}}
                </v-icon>
              </div>
              <div
                v-else-if="item.is_approved_board===1 && item.is_done_teller===0 &&position==='TELLER'">
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="loans_dataaa(item)"
                >
                  {{icons.mdiToggleSwitchOff}}
                </v-icon>
              </div>
            </td>
            <td class="text-center" v-if="position==='GENERAL MANAGER'||position==='ADMIN'">
              <div>
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="dis_approved(item)"
                >
                  {{icons.mdiCancel}}
                </v-icon>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />

    <withdrawal-slips-share-capital
      v-on:dialog_show="on_add_share_withdrawal_dialog_close"
      :dialog_show="add_dialog_share_withdrawal"
      :member_idd="member_id"
      :share_capital_approval_id="share_capital_approval_id"
    />
  </div>
</template>

<script>
  import {
    mdiPrinter,
    mdiToggleSwitchOff,
    mdiToggleSwitch,
    mdiEyeCircle,
    mdiCancel,
    mdiCheckBold
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import WithdrawalSlipsShareCapital
    from '@/views/load_interface/withdrawals_interface/WithdrawalSlipsShareCapital'

  export default {

    components: {
      snackBarDialog,
      WithdrawalSlipsShareCapital,
    },
    mounted() {
      this.list_of_loans_not_ye_approve()
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiToggleSwitchOff,
          mdiCheckBold,
          mdiToggleSwitch,
          mdiEyeCircle,
          mdiCancel,
        },
      }
    },
    data() {
      return {
        add_dialog_share_withdrawal: false,

        can_print: false,
        loans_items: [],
        dialogs: false,
        view_dialog: false,
        member_id: 0,
        share_capital_approval_id: 0,
        co_maker_id: 0,
        mode_of_payment: '',
        terms: 0,
        amount: 0,
        share_capital: 0,
        regular_savings: 0,
        key: 0,
        id: 0,
        value: {},
        type_of_loan: '',
        specify_type_of_loan: '',
        purpose_of_loan: '',
      }
    },
    computed: {
      ...mapGetters('authentication', ['branch_id', 'user_id', 'position', 'branch', 'branch_address', 'branch_contact_no', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('share_capital', ['list_of_share_capital_not_yet_approve', 'approved_share_capital_request', 'dis_approved_share_capital_request']),
      on_add_share_withdrawal_dialog_close(value) {
        this.add_dialog_share_withdrawal = value
        this.list_of_loans_not_ye_approve()
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      actions(item) {
        const data = new FormData()
        data.append('id', item.id);
        data.append('position', this.position);
        data.append('approver_id', this.user_id);
        this.approved_share_capital_request(data)
          .then(() => {
            this.list_of_loans_not_ye_approve()
          })
          .catch(error => {
            console.log(error)
          })
      },
      dis_approved(item) {
        const data = new FormData()
        data.append('id', item.id);
        data.append('approver_id', this.user_id);
        data.append('position', this.position);
        this.dis_approved_share_capital_request(data)
          .then(() => {
            this.list_of_loans_not_ye_approve()
          })
          .catch(error => {
            console.log(error)
          })
      },
      view(item) {
        this.value = item
        this.view_dialog = true
        this.key += 1
      },
      loans_dataaa(item) {
        this.value = item
        this.member_id = item.member_id
        this.share_capital_approval_id = item.id
        this.add_dialog_share_withdrawal = true

      },
      list_of_loans_not_ye_approve() {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        this.list_of_share_capital_not_yet_approve(data)
          .then(response => {
            this.loans_items = response.data
            this.can_print = true
          })
          .catch(error => {
            console.log(error)
          })
      },
      on_save_loan() {
        this.dialogs = false
        this.list_of_loans_not_ye_approve()
      },
      on_save_loan_reconstrcu() {
        this.view_dialog = false
        this.list_of_loans_not_ye_approve()
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'TYPE OF LOAN', alignment: 'center', style: 'label'},
            {text: 'NAME', alignment: 'center', style: 'label'},
            {text: 'AMOUNT', alignment: 'center', style: 'label'},
            {text: 'STATUS', alignment: 'center', style: 'label'},
          ]
        )
        if (this.loans_items.length > 0) {
          this.loans_items.forEach(function (item, index) {

            var status = 'NEED VERIFY'
            if (item.status === 1) {
              status = 'NEED APPROVAL'
            } else if (item.status === 2) {
              status = 'APPROVED'
            }
            array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.type_of_loan, alignment: 'left'},
                {text: item.members.last_name + ', ' + item.members.first_name, alignment: 'left'},
                {
                  text: item.amount,
                  alignment: 'left'
                },
                {text: status, alignment: 'center'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF LOANS NOT YET APPROVE', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [40, 60, 230, 70, 100],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
